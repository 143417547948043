import React, { useCallback, useEffect, useState, useContext } from "react";
import { useDistrict } from "../Breadcrumbs/Breadcrumbs";
import axios from "../../api/axios";
import { useNavigate } from "react-router-dom";
import { SyncReportsContext } from "../../context/SyncReportsProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBug, faCheck, faUpload } from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Col,
  Modal,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
} from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import "./ImportData.css";

// Define a constant for the token so we don’t repeat it
const AUTH_TOKEN = "Bearer YOUR_TOKEN_HERE";

const ImportData = () => {
  const [file, setFile] = useState(null);
  const [importLogError, setImportLogError] = useState("");
  const [loading, setLoading] = useState(false);
  const [logsData, setLogsData] = useState([]);
  const [modalMessage, setModalMessage] = useState("");
  const [showModal, setShowModal] = useState(false);

  const { selectedDistrict } = useDistrict();
  const navigate = useNavigate();
  const { refreshData } = useContext(SyncReportsContext);

  const fetchLogs = useCallback(async () => {
    try {
      const response = await axios.get(
        `/cc/transaction/district/${selectedDistrict.irn}`,
        {
          headers: { Authorization: AUTH_TOKEN },
        }
      );

      if (response.data.error) {
        setImportLogError(response.data.error);
      } else {
        const logs = response.data
          .map((log) => ({
            ...log,
            transaction_log_date: new Date(
              log.transaction_log_date.substring(0, 25)
            ),
          }))
          .sort((a, b) => b.transaction_log_date - a.transaction_log_date)
          .slice(0, 120);

        setLogsData(logs);
      }
    } catch (error) {
      setImportLogError("Failed to fetch logs.");
    }
  }, [selectedDistrict]);

  useEffect(() => {
    if (selectedDistrict?.irn) {
      fetchLogs();
    }
  }, [fetchLogs, selectedDistrict]);

  // Setup react-dropzone for drag & drop file selection
  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      setFile(acceptedFiles[0]);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
  });

  const uploadData = useCallback(async () => {
    if (!file) return;

    // Determine the base URL based on the current hostname.
    const baseUrl =
      window.location.hostname.includes("localhost") ||
      window.location.hostname.includes("qa")
        ? "https://cc-api-qa.metasolutions.net"
        : "https://cc-api.metasolutions.net";

    const formData = new FormData();
    formData.append("fileToUpload", file);

    setLoading(true);

    try {
      // Import endpoint uses a relative URL (axios instance already has baseURL configured)
      const importResponse = await axios.post(
        `/cc/import/${selectedDistrict.irn}`,
        formData,
        {
          headers: {
            Authorization: AUTH_TOKEN,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (importResponse.data.success) {
        try {
          // Use the dynamic baseUrl for the cache invalidation endpoint.
          await axios.post(
            `${baseUrl}/invalidate_cache/district/${selectedDistrict.irn}`,
            {},
            {
              headers: {
                Authorization: AUTH_TOKEN,
                "Content-Type": "application/json",
              },
            }
          );

          setModalMessage(
            "Data imported successfully! Closing this modal will redirect you to the Welcome Page to sync your files."
          );
        } catch (invalidateError) {
          console.error("Error invalidating cache:", invalidateError);
          setModalMessage(
            "Import succeeded but failed to invalidate cache. Please try again."
          );
        }
        fetchLogs(); // Refresh logs after a successful import.
      } else {
        setModalMessage(importResponse.data.message);
      }
    } catch (error) {
      setModalMessage(error.response?.data?.message || "Upload Failed");
    } finally {
      setLoading(false);
      setShowModal(true);
    }
  }, [file, selectedDistrict, fetchLogs]);

  // Handler for modal close that refreshes data and navigates home
  const handleModalClose = useCallback(() => {
    setShowModal(false);
    refreshData();
    navigate("/");
  }, [refreshData, navigate]);

  return (
    <div className="import-data-container">
      <Row>
        <Col>
          <h3 className="my-4">Import Data</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <h5>Importing Files to CrossCheck</h5>
          <p>
            Upon initial setup in CrossCheck, you will be importing any
            historical student collection data that is no longer available on
            the collection requests tab of the data collector from the past 3
            fiscal years. This only needs to be done one time. For example,
            FY20S data is no longer available but is necessary for the 3‑year
            lookback special education files:
          </p>
          <ol>
            <li>Log into the Data Collector</li>
            <li>
              Locate the collection you want to generate a file for in the
              archives tab.
            </li>
            <li>
              The files you import must be from the archives tab. If no archive
              exists for the desired collection, create one.
            </li>
            <li>Download the last collection preview files .zip.</li>
            <li>
              Drag and drop the downloaded zip file into the area below, or
              click to select a file.
            </li>
            <li>Click on Import.</li>
            <li>
              Repeat to import all the zip files generated from the Data
              Collector.
            </li>
          </ol>
          <h5 className="mt-4">Select file to upload</h5>
          <div
            {...getRootProps()}
            className={`dropzone ${isDragActive ? "active" : ""}`}
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Drop the file here ...</p>
            ) : file ? (
              <p>{file.name}</p>
            ) : (
              <p>Drag and drop a file here, or click to select one</p>
            )}
          </div>
          {file && (
            <Button
              className="mt-2 upload-btn"
              variant="primary"
              onClick={uploadData}
              disabled={loading}
            >
              {loading ? (
                "Uploading..."
              ) : (
                <>
                  <FontAwesomeIcon icon={faUpload} /> Upload File
                </>
              )}
            </Button>
          )}
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <h5>Import History</h5>
          <div className="table-wrapper">
            <Table striped bordered hover size="sm">
              <thead className="thead-dark">
                <tr>
                  <th>Fiscal Year</th>
                  <th>Dataset</th>
                  <th>Record Type</th>
                  <th>Import User</th>
                  <th style={{ width: "175px" }}>Import Date</th>
                  <th className="text-right">Records</th>
                  <th className="text-center">Success</th>
                </tr>
              </thead>
              <tbody>
                {logsData.map((log, index) => {
                  const importDate = new Date(log.transaction_log_date);
                  return (
                    <tr key={index}>
                      <td>{log.fiscal_year}</td>
                      <td>{log.dataset?.description}</td>
                      <td>
                        {log.dataset?.name}
                        {log.sub_type && ` - ${log.sub_type}`}
                      </td>
                      <td>{`${log.user?.first_name} ${log.user?.last_name}`}</td>
                      <td>
                        {importDate.toLocaleDateString()}
                        <br />
                        {importDate.toLocaleTimeString()}
                      </td>
                      <td className="text-right">{log.records_inserted}</td>
                      <td className="text-center">
                        {log.success ? (
                          <OverlayTrigger
                            overlay={
                              <Tooltip id={`tooltip-success-${index}`}>
                                {log.message ?? "Success"}
                              </Tooltip>
                            }
                          >
                            <span>
                              <FontAwesomeIcon icon={faCheck} color="green" />
                            </span>
                          </OverlayTrigger>
                        ) : (
                          <OverlayTrigger
                            overlay={
                              <Tooltip id={`tooltip-failure-${index}`}>
                                {log.message ?? "Failed"}
                              </Tooltip>
                            }
                          >
                            <span>
                              <FontAwesomeIcon icon={faBug} color="red" />
                            </span>
                          </OverlayTrigger>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            {importLogError && (
              <div className="alert alert-danger">{importLogError}</div>
            )}
          </div>
        </Col>
      </Row>
      <Modal show={showModal} onHide={handleModalClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Upload Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ImportData;
