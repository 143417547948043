// import React, { useContext } from "react";
import React from "react";
// import { SyncReportsContext } from "../../context/SyncReportsProvider";
import {
  Alert,
  Button,
  // Card,
  // ListGroup,
  Modal,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import "./UserPayloadCard.css";

const UserPayloadCard = ({ payload, onClose, onConfirm }) => {
  // const { fullMenu } = useContext(SyncReportsContext);

  // // Build a lookup for the user's report access.
  // const userAccess = payload.reports.reduce((acc, report) => {
  //   acc[report.report_id] = report.access;
  //   return acc;
  // }, {});

  // Filter fullMenu to include only menu items that have at least one
  // primary report (without a parent_report_id) with access.
  // const accessibleMenuItems = fullMenu.filter((menuItem) => {
  //   const accessibleReports = menuItem.reports.filter(
  //     (report) => userAccess[report.report_id] && !report.parent_report_id
  //   );
  //   return accessibleReports.length > 0;
  // });

  return (
    <Modal
      show
      onHide={onClose}
      backdrop="static"
      keyboard={false}
      centered
      size="lg"
      className="user-payload-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Confirm User Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid className="user-payload-container">
          {/* User Details Container */}
          <Row className="mb-4 justify-content-center">
            <Col xs={12} md={8}>
              <div className="user-details-container">
                <h4 className="details-heading">User Form Data</h4>
                <div className="form-group">
                  <label>First Name:</label>
                  <div className="form-control-plaintext">
                    {payload.first_name}
                  </div>
                </div>
                <div className="form-group">
                  <label>Last Name:</label>
                  <div className="form-control-plaintext">
                    {payload.last_name}
                  </div>
                </div>
                <div className="form-group">
                  <label>Username:</label>
                  <div className="form-control-plaintext">
                    {payload.username}
                  </div>
                </div>
                {/* We don't want the password but keep the code just in case */}
                {/* {payload.newpassword && (
                  <div className="form-group">
                    <label>Password:</label>
                    <div className="form-control-plaintext">
                      {payload.newpassword || payload.password}
                    </div>
                  </div>
                )} */}
              </div>
            </Col>
          </Row>

          {/* Accessible Menu Items Section */}
          {/* <Row className="mb-3">
            <Col xs={12}>
              <h4 className="section-title">Accessible Menu Items</h4>
            </Col>
          </Row>
          <Row className="justify-content-center">
            {accessibleMenuItems.length > 0 ? (
              accessibleMenuItems.map((menuItem) => {
                const accessibleReports = menuItem.reports.filter(
                  (report) =>
                    userAccess[report.report_id] && !report.parent_report_id
                );
                return (
                  <Col xs={12} md={8} key={menuItem.menu_id} className="mb-3">
                    <Card className="menu-item-card">
                      <Card.Body>
                        <Row>
                          <Col xs={12} md={4}>
                            <h5 className="menu-title">{menuItem.title}</h5>
                          </Col>
                          <Col xs={12} md={8}>
                            <ListGroup variant="flush" className="report-list">
                              {accessibleReports.map((report) => (
                                <ListGroup.Item key={report.report_id}>
                                  {report.menu_title || report.title}
                                </ListGroup.Item>
                              ))}
                            </ListGroup>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                );
              })
            ) : (
              <Col xs={12} className="text-center">
                <p className="text-muted">No accessible menu items.</p>
              </Col>
            )}
          </Row> */}

          {/* User Alert Section */}
          <Row className="justify-content-center">
            <Col xs={12} md={8}>
              <Alert variant="success" className="save-alert">
                <Alert.Heading>Confirm User Details</Alert.Heading>
                <p>
                  Review the user details and menu access before saving. New
                  users can log in with their assigned credentials and will be
                  required to change their password upon first login.
                </p>
              </Alert>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Button variant="success" onClick={onConfirm}>
          Confirm
        </Button>
        <Button variant="danger" onClick={onClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UserPayloadCard;
